import $api from '@/api';
import $router from '@/router';

const store = {
  state: {
    access: '',
    refresh: '',
    secruity: '',
    userinfo: {
      name: null,
      authority: null,
      color: null,
    },
  },
  getters: {
    access: (state) => {
      return state.access;
    },
    refresh: (state) => {
      return state.refresh;
    },
    secruity: (state) => {
      return state.secruity;
    },
    userinfo: (state) => {
      return state.userinfo;
    },
  },
  mutations: {
    access(state, payload) {
      state.access = payload;
    },
    refresh(state, payload) {
      state.refresh = payload;
    },
    secruity(state, payload) {
      state.secruity = payload;
    },
    userinfo(state, payload) {
      state.userinfo = payload;
    },
  },
  actions: {
    login(context, payload) {
      $api.login(payload)
      .then((res) => {
        if(res.data.code === 200) {
          context.commit('access', res.data.result.access_token);
          context.commit('refresh', res.data.result.refresh_token);
          context.commit('userinfo', {name: res.data.result.name, authority: res.data.result.authority, color: res.data.result.color});

          $router.replace({ path: '/' });

        } else if(res.data.code == 40305) {
          document.getElementById('btnError').click();
        }
      })
      .catch((err) => {
        console.log("err", err)
        throw err;
      });
    },
    logout(context) {
      context.commit('access', '');
      context.commit('refresh', '');
      context.commit('userinfo', {
        name: null,
        authority: null,
        color: null,
      });
      $router.push({ name: 'login' });
    },
  }
}

export default store