<template>
  <header class="layout-header">
    <div class="container">
      <nav class="navbar desktop">
        <h1 class="d-flex align-items-center">
          <router-link to="/" class="navbar-brand">
            <img v-if="this.$route.name == 'index'" src="@/assets/images/logo/logo_white.svg" alt="logo-iFood-white">
            <img v-else src="@/assets/images/logo/logo-black.svg" alt="logo-iFood">
          </router-link>
        </h1>
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav desktop">
            <!-- 로그인 된 유저 nav-->
            <template v-if="userinfo.authority != null">
              <li class="nav-item dropdown">
                <router-link :to="{ name:'dietList'}" class="nav-link px-20" role="button">식단 목록</router-link>
              </li>
              <li class="nav-item dropdown line-none">
                <div class="nav-link btn user px-8">
                  <!-- color:${userinfo.color} -->
                  <span class="circle">{{userinfo.name}}</span> 
                </div>
                <div class="dropdown-menu pt-16">
                  <div class="dropdown-wrap">
                    <!--<button type="button" class="dropdown-item">마이페이지</button>-->
                    <button type="button" class="dropdown-item" @click="logout()">로그아웃</button>
                  </div>
                </div>
              </li>
            </template>
            <template v-else>
            <!-- 비 로그인 -->
            <li class="nav-item dropdown line-none ml-auto">
              <div class="nav-link btn user px-8">
                <span class="circle gray"><i class="inco inco-user"></i></span>
              </div>
              <div class="dropdown-menu pt-16">
                <div class="dropdown-wrap">
                  <router-link :to="{ name:'login'}" class="dropdown-item">로그인</router-link>
                </div>
              </div>
            </li>
            </template>
          </ul>
          <ul class="navbar-nav mobile">
            <li>
              <a href="javascript:void(0)">
                <i class="inco inco-menu"><span class="sr-only">메뉴 열기</span></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <nav class="navbar mobile">
        <ul class="navbar-nav">
          <li class="nav-top mb-24">
            <button type="button" class="btn-navbar-close btn-icon">
              <i class="inco inco-menu"><span class="sr-only">메뉴 닫기</span></i>
            </button>
          </li>
          <li class="nav-item">
            <div v-if="userinfo.authority != null" class="accordion" id="navbarNav">
              <router-link :to="{ name:'dietList'}" class="btn btn-navbar-close">
                <span>식단목록</span>
              </router-link>
              <button type="button" class="btn btn-navbar-close" @click="logout()">로그아웃</button>
            </div>
            <div v-else class="accordion" id="navbarNav">
              <router-link :to="{ name:'login'}" class="btn btn-navbar-close">
                <span>로그인</span>
              </router-link>
              <!--<router-link to="#" class="btn btn-navbar-close">
                <span>회원가입</span>
              </router-link>-->
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppHeader',
  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters({
      userinfo: 'token/userinfo',
    }),
  },
  methods: {
    ...mapActions({
      dispatchLogout: 'token/logout',
    }),
    logout() {
      this.dispatchLogout();
    }
  },
}
</script>
