let BASE_URL = '';
let API_KEY = '';

if(process.env.NODE_ENV === 'production') {
  // 배포(build)
  BASE_URL = 'https://ifoodplan.insilicogen.com';
  // BASE_URL = 'https://example.com';
  API_KEY= 'KL6Hf6hj.mhOwtCGmd9pzWAbepxrAhAaMSVNSMv4ifood_a';
} else {
  // 개발(dev)
  BASE_URL= 'https://ifoodplan.insilicogen.com';
  //BASE_URL = 'http://127.0.0.1:8303';
  //BASE_URL= 'http://100.75.189.85:8303';
  API_KEY= 'KL6Hf6hj.mhOwtCGmd9pzWAbepxrAhAaMSVNSMv4ifood_a';
  // BASE_URL= 'http://127.0.0.1:8414';
}

export default {
  BASE_URL,
  API_KEY
}