import Vue from 'vue';
import VueRouter from 'vue-router';
import $store from '@/store';

Vue.use(VueRouter);

const routes = [
  /**
  * Error
  */
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '/400',
    component: () => import('@/views/error/400.vue'),
    name: 'WrongData',
  },
  {
    path: '/404',
    component: () => import('@/views/error/404.vue'),
    name: 'PageNotFound',
  },
  {
    path: '/500',
    component: () => import('@/views/error/500.vue'),
    name: 'InternalServerError',
  },
  /**
    * Index
    */
  {
    path: '/',
    component: () => import('@/views/Index.vue'),
    name: 'index',
  },
  {
    path: '/page',
    component: () => import('@/views/PageList.vue'),
    name: 'PageList',
  },
  /**
    * Login
    */
  {
    path: '/login',
    component: () => import('@/views/Login.vue'),
    name: 'login',
  },
  {
    path: '/diet/list',
    component: () => import('@/views/Diet/DietList.vue'),
    name: 'dietList',
  },
  {
    path: '/diet/create',
    component: () => import('@/views/Diet/DietCreate.vue'),
    name: 'dietCreate',
  },
  {
    path: '/diet/create/:id',
    component: () => import('@/views/Diet/DietCreateEdit.vue'),
    name: 'dietCreateEdit',
    props: true,
  },
  {
    path: '/diet/info/:id',
    component: () => import('@/views/Diet/Info/DietInfo.vue'),
    name: 'dietInfo',
    props: true,
  },
  {
    path: '/diet/info/:id/:day/:seq/:dish',
    component: () => import('@/views/Diet/Info/Daily/DietDaily.vue'),
    name: 'dietDaily',
    props: true,
  },
  {
    path: '/loading',
    component: () => import('@/components/Loading.vue'),
    name: 'loading',
  },
  {
    path: '/test/icon',
    component: () => import('@/views/Test/TestIcon.vue'),
    name: 'icon',
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});


//// Navigation Guard
router.beforeEach((to, from, next) => {
  const is_notLogin = !$store.getters['token/access']; // Adjust according to your userInfo structure

  if(is_notLogin) {
    // 로그인 없이 index, login 외 페이지는 못 감.
    if((to.path == '' || to.path == '/' || to.path == '/login' || to.path == '/test/icon')) {
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
});


export default router;
