import Vue from 'vue';
import Axios from 'axios'
import config from './config'
import $store from '@/store';
import $router from '@/router';

// 프로젝트 설정에 맞게, 기본적인 정보 입력
const axios = Axios.create({
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  baseURL: config.BASE_URL,
  withCredentials: true,
  headers: {
    'Api-Key': config.API_KEY,
  },
});

Vue.prototype.$axios = axios;

// 요청에 필요한 처리를 입력
axios.interceptors.request.use(
  (config) => {
    // config.data가 없는 경우
    //if (!config.data) {
    //  // 에러를 생성하고 거부
    //  const error = new Error('Request data is missing');
    //  error.response = { status: 401, data: 'Request data is missing' };
    //  return Promise.reject(error);
    //}
    
    config.headers['Authorization'] = 'Bearer ' + $store.getters['token/access'];
    
    if(config.url.includes('download?')) {
      config['responseType'] = 'blob';
    }
    return config;
  },
  (err) => {
    Promise.reject(err)
  }
);

// 응답에 필요한 처리를 입력
axios.interceptors.response.use(
  (res) => res,
  error => {
    let tokenStatus = error.response.status;
    console.log("api error", tokenStatus)
    if (tokenStatus == 403 || tokenStatus == 401) {
      // 토큰 만료 처리
      $store.getters['token/access'] == '';
      $store.getters['token/refresh'] == '';
      $store.getters['token/userinfo'] == null;

      $store.dispatch('token/logout');
      //$router.push('/');
      $router.push({ name: 'login' });
    } else if (tokenStatus == 400) {
      $router.push({ name: 'WrongData' });
    } else if (tokenStatus == 404) {
      $router.push({ name: 'PageNotFound' });
    } else if (tokenStatus == 500) {
      $router.push({ name: 'InternalServerError' });
    }
    return Promise.reject(error);
  }
  
)


// 각 메소드별 함수 생성
export default {
  async get(...options) {
    try {
      const res = await axios.get(...options)
      return res;
    } catch (err) {
      return err.response;
    }
  },
  async post(...options) {
    try {
      const res = await axios.post(...options)
      return res;
    } catch (err) {
      return err.response;
    }
  },
  async put(...options) {
    try {
      const res = await axios.put(...options)
      return res
    } catch (err) {
      return err.response;
    }
  },
  async delete(url, options = {}) {
    try {
      const res = await axios.delete(url, options)
      return res
    } catch (err) {
      return err.response;
    }
  },
  async patch(...data) {
    return await axios.patch(...data);
  }
}