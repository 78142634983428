import server from './server';
import $router from '@/router';

export default {
  test(options) {
    return server.get('/test', options);
  },
  // #region Token
  login(...data) {
    return server.post('/api/login', ...data);
  },

  // #region Diet
  getDietList() {
    return server.get('/api/diet');
  },
  getDietForm() {
    return server.get('/api/diet/info');
  },
  postDietCreate(...data) {
    return server.post('/api/diet', ...data);
  },
  updateDietInfo(...data) {
    return server.patch('/api/diet/' + $router.history.current.params.id, ...data);
  },
  postDietAi(...data) {
    return server.post('/api/diet/ai', ...data);
  },
  deleteDiet(data) {
    return server.delete('/api/diet', {data});
  },
  getDietCreated() {
    return server.get('/api/diet/' + $router.history.current.params.id);
  },

  patchDailydiet(data) {
    return server.patch('/api/diet/info/dailydiet/' + $router.history.current.params.id + '/' + data['week'] + '/' + data['day']);
  },
  patchDailyDietConfirm() {
    return server.patch('/api/diet/' + $router.history.current.params.id + '/confirm',
      {
        "week": 1,
        "day": $router.history.current.params.day,
        "is_confirm": true
      }
    );
  },

  getDailyDietInfo() {
    return server.get('/api/diet/info/' + $router.history.current.params.id + '/' + $router.history.current.params.seq + '/dish/' + $router.history.current.params.dish);
  },
  getDailyDishOrder(data) {
    return server.get('/api/diet/info/' + $router.history.current.params.id + '/' + $router.history.current.params.seq + '/dish/' + $router.history.current.params.dish + '?order=' + data['nutrient']);
  },
  
  patchDailyDish(...data) {
    return server.patch('/api/diet/info/' + $router.history.current.params.id + '/' + $router.history.current.params.seq + '/dish/' + $router.history.current.params.dish, ...data);
  },
  resetDailyDiet(data) {
    return server.patch('/api/diet/info/' + data['diet_id'] + '/reset');
  },
  getDailyDietReview() {
    return server.get('/api/diet/info/dailydiet/review/' + $router.history.current.params.id + '/1/' + $router.history.current.params.day );
  },
  getDailyDietNeis(data) {
    return server.get('/api/diet/info/dailydiet/review/neis/' + $router.history.current.params.dish + '/' + data['ingredient']);
  },
  patchDailyDietNeis(data) {
    return server.patch('/api/diet/info/dailydiet/review/neis/' + $router.history.current.params.dish + '/' + data['ingredient'], data['selected']);
  },
  getSearchedNeis(data) {
    return server.get('/api/diet/info/dailydiet/review/neis/search/' + $router.history.current.params.dish + '?ingredient_name=' + data['search']);
  },
  patchEmptyNeis(data) {
    return server.patch('/api/diet/info/dailydiet/review/neis/ingredient/' + $router.history.current.params.dish, data['selected']);
  },
  postNeisReset() {
    return server.post('/api/diet/info/dailydiet/review/' + $router.history.current.params.id + '/1/' + $router.history.current.params.day + '/' + $router.history.current.params.dish + '/reset');
  },

  getExcelDownload() {
    return server.get('/api/diet/info/download-excel/' + $router.history.current.params.id );
  },
  getPdf() {
    return server.get('/api/diet/info/pdf/' + $router.history.current.params.id );
  },
  // #endregion Diet
}