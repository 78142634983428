import $api from '@/api';
import $router from '@/router';
import Vue from 'vue';

const store = {
  state: {
    dietList:  null,
    dietForm: {
      school_type_list: [],
      meals_list: null,
      period_list: null,
      includes_snacks_list: null,
      recent_number_of_people: null
    },
    dietCreated: {
      status: null,
      diet_info: {},
      diet_list: [
        {
          days: [{}, {}, {}, {}, {}]
        }
      ]
    },
    dailyDietInfo: {
      diet_detail: {
        is_confirm: null
      }
    },
    dailyDietDetail: {
      is_confirm: null,
    },
    dailyDietReview: null,
    dailyDietNeis: null,
    searchedNeis: null,
    downloadPdf: null,
  },
  getters: {
    dietList: (state)  => {
      return state.dietList;
    },
    dietForm: (state)  => {
      return state.dietForm;
    },
    dietCreated: (state)  => {
      return state.dietCreated;
    },
    //dietCreatedInfo: (state)  => {
    //  return state.dietCreated.diet_info;
    //},
    dailyDietInfo: (state)  => {
      return state.dailyDietInfo;
    },
    dailyDietDetail: (state)  => {
      return state.dailyDietDetail;
    },
    dailyDietReview: (state)  => {
      return state.dailyDietReview;
    },
    dailyDietNeis: (state)  => {
      return state.dailyDietNeis;
    },
    searchedNeis: (state)  => {
      return state.searchedNeis;
    },
    downloadPdf: (state)  => {
      return state.downloadPdf;
    },
  },
  mutations: {
    dietList(state, payload) {
      state.dietList = payload;
    },
    dietForm(state, payload) {
      state.dietForm = payload;
    },
    dietCreated(state, payload) {
      state.dietCreated = payload;
    },
    dailyDietInfo(state, payload) {
      state.dailyDietInfo = payload;
    },
    dailyDietDetail(state, payload) {
      state.dailyDietDetail = payload;
    },
    dailyDietReview(state, payload) {
      state.dailyDietReview = payload;
    },
    dailyDietNeis(state, payload) {
      state.dailyDietNeis = payload;
    },
    searchedNeis(state, payload) {
      state.searchedNeis = payload;
    },
    downloadPdf(state, payload) {
      state.downloadPdf = payload;
    },
  },
  actions: {
    dietList(context) {
      Vue.prototype.$utils.startLoading();
      return new Promise((resolve, reject) => {
        $api.getDietList()
        .then((res) => {
          //console.log('dietList', res.data.result);
          context.commit('dietList', res.data.result);
          Vue.prototype.$utils.endLoading();
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err)
          context.commit('dietList', null);
          Vue.prototype.$utils.endLoading();
          reject(err);
        });
      });
    },
    dietForm(context) {
      return new Promise((resolve, reject) => {
        $api.getDietForm()
        .then((res) => {
          // 학교 select 형식에 맞게 수정
          res.data.result.group.school_type_list = res.data.result.group.school_type_list.map(option => {
            return {
              label: option.school_type_value, // 원본 옵션의 label 사용
              id: option.school_type_code // 원본 옵션의 id를 value로 사용
            };
          });

          // "유치원" 항목 제거
          res.data.result.group.school_type_list = res.data.result.group.school_type_list.filter(item => item.label !== "유치원");
          
          // 구분 일간 없애기
          res.data.result.group.period_list = res.data.result.group.period_list.filter(period => period.period_code !== 'PR001');
          
          //console.log('dietForm', res.data.result.group);
          context.commit('dietForm', res.data.result.group);
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err)
          context.commit('dietForm', null);
          reject(err);
        });
      });
    },
    createDiet(context, payload) {
      return new Promise((resolve, reject) => {
        $api.postDietCreate(payload)
        .then(res => {
          if(!payload.is_backSave) {
            $router.replace({ path: '/diet/create/' + res.data.result.pk });
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
      });
    },
    updateDietInfo(context, payload) {
      return new Promise((resolve, reject) => {
        $api.updateDietInfo(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
      });
    },
    createDietAi(context, payload) {
      return new Promise((resolve, reject) => {
        $api.postDietAi(payload)
        .then(res => {
          $router.replace({ path: '/diet/list' });
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
      });
    },
    dietDelete(context, payload) {
      return new Promise((resolve, reject) => {
        $api.deleteDiet(payload)
        .then((res) => {
          context.dispatch('dietList');
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        }); 
      });
    },
    dietCreated(context) {
      Vue.prototype.$utils.startLoading();
      return new Promise((resolve, reject) => {
        $api.getDietCreated()
        .then((res) => {
          //console.log("dietCreated", res.data.result)
          if(res.data.result.status == "ST001") {
            // select 형식에 맞추기
            res.data.result.diet_info.school_type = 
            res.data.result.diet_info.school_type == '유치원' ? 'SCT001' : 'SCT002' ;
            // radio 형식에 맞추기
            res.data.result.diet_info.meals = 
            res.data.result.diet_info.meals == '조식' ? 'ML001' : 
            res.data.result.diet_info.meals == '중식' ? 'ML002' : 'ML003' ;
            res.data.result.diet_info.period = 
            res.data.result.diet_info.period == '주간' ? 'PR002' : 
            res.data.result.diet_info.period == '월간' ? 'PR003' : 'PR001';
            res.data.result.diet_info.includes_snacks = 
            res.data.result.diet_info.includes_snacks == '주 1회' ? 'IS001' : 
            res.data.result.diet_info.includes_snacks == '주 2회' ? 'IS002' : 
            res.data.result.diet_info.includes_snacks == '매일' ? 'IS003' : 'IS004';
            
            // date 형식에 맞추기
            res.data.result.diet_info.diet_date = new Date(res.data.result.diet_info.diet_date);
            // 안쓰는 키 제거
            delete res.data.result.diet_info.create_info_type;
            delete res.data.result.diet_info.pk;
            // diet_list 빈값 넣기
            res.data.result.diet_list = [{
              days: [{}, {}, {}, {}, {}]
            }];

          } else if(res.data.result.status == "ST003") {
            res.data.result.diet_list[0].days.forEach((item) => {
              // 간식 유무에 따라 값 추가
              item.has_snack = item.day_diet_list[0].is_snack;
              // 급식년월을 기준으로 월-금 날짜 추가
              item.weekdate = Vue.prototype.$utils.datePlus(res.data.result.diet_info.diet_date, parseInt(item.day_order));
            });
          }
          
          context.commit('dietCreated', res.data.result);
          Vue.prototype.$utils.endLoading();
          resolve(res);
        })
        .catch((err) => {
          context.commit('dietCreated', null);
          Vue.prototype.$utils.endLoading();
          reject(err);
        });
      });
    },

    refreshDailydiet(context, payload) {
      //Vue.prototype.$utils.startLoading();
      return new Promise((resolve, reject) => {
        $api.patchDailydiet(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
      });
    },
    dailyDietConfirm(context) {
      return new Promise((resolve, reject) => {
        $api.patchDailyDietConfirm()
        .then((res) => {
          let newObj = this.state.diet.dailyDietInfo;
          newObj.diet_detail.is_confirm = true;
          context.commit('dailyDietInfo', newObj);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
      });
    },
    dailyDietInfo(context) {
      context.commit('dailyDietInfo', null);
      Vue.prototype.$utils.startLoading();
      return new Promise((resolve, reject) => {
        $api.getDailyDietInfo()
        .then((res) => {
          // 변경 사유 select 형식에 맞게 수정
          res.data.result.reason_list = res.data.result.reason_list.map(option => {
            return {
              label: option.change_reason_name, // 원본 옵션의 label 사용
              id: option.change_reason_id // 원본 옵션의 id를 value로 사용
            };
          });

          //console.log('dailyDietDetail', res.data.result);
          context.commit('dailyDietInfo', res.data.result);
          context.commit('dailyDietDetail', res.data.result.diet_detail);
          Vue.prototype.$utils.endLoading();
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err)
          context.commit('dailyDietInfo', null);
          Vue.prototype.$utils.endLoading();
          reject(err);
        });
      });
    },
    dailyDishList(context, payload) {
      Vue.prototype.$utils.startLoading();
      return new Promise((resolve, reject) => {
        $api.getDailyDishOrder(payload)
        .then((res) => {
          let newObj = this.state.diet.dailyDietInfo;
          newObj.change_dish_list = res.data.result;
          context.commit('dailyDietInfo', newObj);

          Vue.prototype.$utils.endLoading();
          resolve(res);
        })
        .catch((err) => {
          Vue.prototype.$utils.endLoading();
          console.log("err", err)
          reject(err);
        });
      });
    },
    dailyDietPatch(context, payload) {
      //Vue.prototype.$utils.startLoading();
      return new Promise((resolve, reject) => {
        $api.patchDailyDish(payload)
        .then((res) => {
          // 변경 사유 select 형식에 맞게 수정
          res.data.result.reason_list = res.data.result.reason_list.map(option => {
            return {
              label: option.change_reason_name, // 원본 옵션의 label 사용
              id: option.change_reason_id // 원본 옵션의 id를 value로 사용
            };
          });
          context.commit('dailyDietInfo', res.data.result);

          Vue.prototype.$utils.endLoading();
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err)
          context.commit('dailyDietInfo', null);
          Vue.prototype.$utils.endLoading();
          reject(err);
        });
      });
    },
    dailyDietReset(context, payload) {
      return new Promise((resolve, reject) => {
        $api.resetDailyDiet(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err)
          reject(err);
        });
      });
    },

    dailyDietReview(context, payload) {
      context.commit('dailyDietReview', null);
      return new Promise((resolve, reject) => {
        $api.getDailyDietReview(payload)
        .then((res) => {
          //console.log('dailyDietReview', res.data.result.diet_review_list);
          context.commit('dailyDietReview', res.data.result.diet_review_list);
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err)
          context.commit('dailyDietReview', null);
          reject(err);
        });
      });
    },
    dailyDietNeis(context, payload) {
      return new Promise((resolve, reject) => {
        $api.getDailyDietNeis(payload)
        .then((res) => {
          //console.log("Neis", res.data.result)
          context.commit('dailyDietNeis', res.data.result);
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err)
          reject(err);
        });
      });
    },
    dailyDietNeisPatch(context, payload) {
      return new Promise((resolve, reject) => {
        $api.patchDailyDietNeis(payload)
        .then((res) => {
          let beforeIngr = res.data.result.before_neis_code;
          let dish = $router.history.current.params.dish;
          let seq = $router.history.current.params.seq;

          let newObj = this.state.diet.dailyDietReview.map(obj => {
            if (obj.diet_detail_pk == dish && obj.seq == seq) {
              return {
                ...obj,
                ingredient_list : obj.ingredient_list.map(ingr => {
                  // 조건에 맞는 객체인 경우, newValues로 값을 변경
                  if (ingr.neis_code == beforeIngr) {
                    return { ...ingr, 'ingredient_name': payload.neis_name, 'neis_code': payload.neis_code, };
                  }
                  // 조건에 맞지 않는 경우, 원래 객체를 그대로 반환
                  return ingr;
                })
              }
            }
            return obj;
          });
          context.commit('dailyDietReview', newObj);
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err)
          reject(err);
        });
      });
    },
    searchedNeis(context, payload) {
      return new Promise((resolve, reject) => {
        $api.getSearchedNeis(payload)
        .then((res) => {
          context.commit('searchedNeis', res.data.result);
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err)
          reject(err);
        });
      });
    },
    searchedNeisErase(context) {
      context.commit('searchedNeis', '');
    },
    emptyNeisPatch(context, payload) {
      return new Promise((resolve, reject) => {
        $api.patchEmptyNeis(payload)
        .then((res) => {
          let ingredient_pk = res.data.result.ingredient_pk;
          let beforeIngr = payload.selected.current_ingredient_id;
          let dish = $router.history.current.params.dish;
          let seq = $router.history.current.params.seq;
          // 매핑 안된 식재를 수에 따라 true/false 값 넘기기
          let is_confirm = payload.no_mapping <= 1 ? true : false;

          let newObj = this.state.diet.dailyDietReview.map(obj => {
            if (obj.diet_detail_pk == dish && obj.seq == seq) {
              return {
                ...obj,
                is_confirm : is_confirm,
                ingredient_list : obj.ingredient_list.map(ingr => {
                  // 조건에 맞는 객체인 경우, newValues로 값을 변경
                  if (ingr.ingredient_pk == beforeIngr) {
                    return { ...ingr, 'ingredient_pk': ingredient_pk, 'ingredient_name': payload.neis_name, 'neis_code': payload.neis_code, };
                  }
                  // 조건에 맞지 않는 경우, 원래 객체를 그대로 반환
                  return ingr;
                })
              }
            }
            return obj;
          });
          context.commit('dailyDietReview', newObj);

          if(is_confirm) {
            let dailyInfo = this.state.diet.dailyDietInfo;
            let findObj = dailyInfo.day_diet_list.find(obj => obj.diet_detail_pk == dish && obj.seq == seq);
            findObj.is_confirm = true;
            context.commit('dailyDietInfo', dailyInfo);
          }
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err)
          reject(err);
        });
      });
    },
    dailyDietNeisReset(context, payload) {
      return new Promise((resolve, reject) => {
        $api.postNeisReset(payload)
        .then((res) => {
          let dish = $router.history.current.params.dish;
          let seq = $router.history.current.params.seq;

          let newObj = this.state.diet.dailyDietReview.map(obj => {
            if (obj.diet_detail_pk == dish && obj.seq == seq) {
              // reset 후 식재가 전부 매핑 되었는지,
              // 아니면 dish의 is_confirm false로 바꾸기
              let is_mapping = true;
              res.data.result.ingredient_list.forEach(diet => {
                if(diet.neis_code == '-') {
                  is_mapping = false;
                }
              });
              return {
                ...obj,
                is_confirm: is_mapping,
                ingredient_list : res.data.result.ingredient_list
              }
            }
            return obj;
          });
          context.commit('dailyDietReview', newObj);

          resolve(res);
        })
        .catch((err) => {
          console.log("err", err)
          reject(err);
        });
      });
    },

    downloadExcel(context, payload) {
      return new Promise((resolve, reject) => {
        $api.getExcelDownload(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err)
          reject(err);
        });
      });
    },
    downloadPdf(context, payload) {
      return new Promise((resolve, reject) => {
        $api.getPdf(payload)
        .then((res) => {
          context.commit('downloadPdf', res.data.result.diet_pdf_list);
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err)
          reject(err);
        });
      });
    },
    
  }
}

export default store