import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import api from './api';
import BootstrapVue from 'bootstrap-vue';
import vSelect from 'vue-select'
import LottieAnimation from 'lottie-web-vue'
Vue.use(LottieAnimation);

Vue.use(BootstrapVue);


// Service Worker
// import './registerServiceWorker'

import 'vue2-datepicker/index.css';
// Custom Javscript
import './assets/mjs/main';
import './assets/fonts/iconfont/iconfont.css';
import './assets/fonts/materialicons/materialicons.css';
import './assets/fonts/nexon/nexon.css';
import './assets/fonts/notosanskr/notosanskr.css';
import Utils from './assets/js/custom.fed';
Vue.prototype.$utils = Utils;
Vue.prototype.$global = {};

// Axios API
Vue.prototype.$api = api;



// router 전역 가드를 사용하여 중복 탐색 방지
const originalPush = router.push
router.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

/**
 * EventBus
 * ex)
 * this.$eventBus.$emit('event', data);
 * this.$eventBus.$on('event', (data) => {});
 */
const eventBus = new Vue();
Vue.prototype.$eventBus = eventBus;
Vue.use(eventBus);

// Default Vue Settings
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  api,
  vSelect,
  render: (h) => h(App),
}).$mount('#app');

Vue.component("v-select", vSelect);
