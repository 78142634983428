<template>
  <div class="layout-wrap">
    <!-- header -->
    <Header v-if="this.$route.name != 'pageNotFound' && this.$route.name != 'internalServerError' && this.$route.name != 'login'"
    :class="this.$route.name == 'index' ? 'index' : ''" />

    <!-- content -->
    <main class="layout-main" id="content"
      :class="this.$route.name == 'login' ? 'login' : ''"
    >
      <router-view />
    </main>

    <!-- footer -->
    <Footer v-if="this.$route.name != 'pageNotFound' && this.$route.name != 'internalServerError'" />

    <!-- modal -->
    <Alert
      :modalInfo="modalInfo"
      @confirm="callback"
    />
  </div>
</template>
<style lang="scss">
  @import '@/assets/scss/main';
</style>
<script>
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import Alert from '@/components/modal/Alert.vue';
export default {
  name: 'App',
  data() {
    return {
      modalInfo: {
        type: null,
        message: null,
        subMessage: null,
        cancelBtn: {
          type: '',
          text: '',
        },
        confirmBtn: {
          type: '',
          text: '',
        },
      },
    }
  },
  components: {
    Header,
    Footer,
    Alert
  },
  /**
   * Manage Common Vue Components settings only here.
   * ex)
   * this.$root.$on('name', () => {});
   */
  methods: {
    /**
     * Set Toast Message
     * type: success, info, warning, danger
     * ex) this.$root.$emit('showToast', 'success', '');
     */
    setToast() {
      this.$root.$on('showToast', (type, message) => {
        this.$bvToast.toast(' ', {
          title: message,
          variant: type,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 3000,
        });
      });
    },
    /**
     * Set Alert
     * type: success, info, warning, danger
      let modalInfo = {
        type: '',
        message: '',
        subMessage: '',
        cancelBtn: {
          type: '',
          text: '',
        },
        confirmBtn: {
          type: '',
          text: '',
        },
      }
      this.$root.$emit('showAlert', modalInfo, () => { });
     */
    setAlert() {
      // 이벤트 등록
      this.$root.$on('showAlert', (modalInfo, callback) => {
        if (modalInfo) {
          this.modalInfo = modalInfo;
        }
        if (callback) {
          this.callback = callback;
        } else {
          this.callback = () => {}
        }
        this.$bvModal.show('idAlert');
      });
    },
    callback() {},
  },
  watch: {
    $route(to, from) {
      if (to.path != from.path) {
        const html = document.querySelector('html');
        html.scrollTop = 0;
      }
    }
  },
  mounted() {
    this.setToast();
    this.setAlert();
  },
  created() {
    //if(this.$store.getters['token/access'] == '') {
    //  this.$router.replace({ path: '/login' });
    //}
  },
}
</script>
