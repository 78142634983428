<template>
  <b-modal id="idAlert" centered ok-variant="secondary" hide-header>
    <b-button class="btn-modal-close" block @click="$bvModal.hide('idAlert')"><i class="material-icons">close</i></b-button>
    <div class="content-wrap">
      <h4>{{ modalInfo.message }}</h4>
      <p class="sub-message" v-if="modalInfo.subMessage" v-html="modalInfo.subMessage"></p>
    </div>
    <template #modal-footer>
      <div class="btn-wrap" v-if="modalInfo.type == 'success'">
        <button type="button" class="btn btn-primary btn-lg" @click="confirm">확인하기</button>
      </div>
      <div class="btn-wrap" v-else-if="modalInfo.type == 'info'">
        <button type="button" class="btn btn-gray btn-lg" @click="$bvModal.hide('idAlert')">취소하기</button>
        <button type="button" class="btn btn-info btn-lg" @click="confirm">확인하기</button>
      </div>
      <div class="btn-wrap" v-else-if="modalInfo.type == 'warning'">
        <button type="button" class="btn btn-danger btn-lg" @click="confirm">확인하기</button>
      </div>
      <div class="btn-wrap" v-else-if="modalInfo.type == 'danger'">
        <button type="button" class="btn btn-gray btn-lg" @click="$bvModal.hide('idAlert')">취소하기</button>
        <button type="button" class="btn btn-danger btn-lg" @click="confirm">삭제하기</button>
      </div>
      <div class="btn-wrap" v-else>
        <button type="button" :class="'btn btn-lg ' + modalInfo.cancelBtn.type" @click="cancel()">{{ modalInfo.cancelBtn.text }}</button>
        <button type="button" :class="'btn btn-primary btn-lg ' + modalInfo.confirmBtn.type" @click="confirm">{{ modalInfo.confirmBtn.text }}</button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'AppAlert',
  props: {
    modalInfo: Object,
  },
  methods: {
    confirm() {
      this.$bvModal.hide('idAlert');
      // trigger callback function
      this.$emit('confirm');
    },
    cancel() {
      this.$bvModal.hide('idAlert')
      if(this.modalInfo.cancelBtn.event) {
        this.modalInfo.cancelBtn.event.call(this);
      }
    },
    redirect(name) {
      this.$bvModal.hide('idAlert');
      this.$router.replace({ name, params: {history: this.$route.path} });
    }
  },
}
</script>